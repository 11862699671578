<template>
    <div class="w-full p-16 pt-0">
        <h1><strong>TERMS OF USE</strong></h1>
        <p><br></p>
        <p>Last updated June 1, 2022</p>
        <p><br></p>
        <p>&nbsp;<strong>WEBSITE DISCLAIMER</strong></p>
        <p>The information provided by&nbsp;Medusa Distribution LLC&nbsp;(“we,” “us” or “our”) on&nbsp;<a
                data-cke-saved-href="http://www.medusadistribution.com/" href="http://www.medusadistribution.com/"
                target="_blank">www.medusadistribution.com</a>&nbsp;(the “Site”)&nbsp;is for general informational
            purposes only. All information on the Site&nbsp;is provided in good faith, however we make no representation
            or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability,
            availability or completeness of any information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
            LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE&nbsp;OR
            RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE&nbsp;AND YOUR RELIANCE ON ANY
            INFORMATION ON THE SITE&nbsp;IS SOLELY AT YOUR OWN RISK.</p>
        <p><br></p>
        <p><strong>﻿EXTERNAL LINKS DISCLAIMER</strong></p>
        <p>The&nbsp;Site&nbsp;may contain (or you may be sent through the&nbsp;Site&nbsp;links to other websites or
            content belonging to or originating from third parties or links to websites and features in banners or other
            advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy,
            validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED
            THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY
            TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
            PRODUCTS OR SERVICES.</p>
        <p><br></p>
        <p><br></p>
        <p><strong>AGREEMENT TO TERMS</strong></p>
        <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf
            of an entity (“you”) and&nbsp;Medusa Distribution LLC&nbsp;(“we,” “us” or “our”), concerning your access to
            and use of the&nbsp;<a data-cke-saved-href="http://www.medusadistribution.com/"
                href="http://www.medusadistribution.com/" target="_blank">www.medusadistribution.com</a>&nbsp;website as
            well as any other media form, media channel, mobile website or mobile application related, linked, or
            otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read,
            understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS
            OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
            IMMEDIATELY.&nbsp;&nbsp;</p>
        <p>Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby
            expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
            modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by
            updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice
            of each such change. It is your responsibility to periodically review these Terms of Use to stay informed of
            updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the
            changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of
            Use are posted.&nbsp;</p>
        <p>The information provided on the Site is not intended for distribution to or use by any person or entity in
            any jurisdiction or country where such distribution or use would be contrary to law or regulation or which
            would subject us to any registration requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on their own initiative and are solely
            responsible for compliance with local laws, if and to the extent local laws are applicable.&nbsp;</p>
        <p>The Site is intended for users who are at least 18 years old or are of legal age to view material intended
            for adults in the jurisdiction in which they reside and/or the jurisdiction&nbsp;from which they access this
            site. Persons under the age of 18 are not permitted to use or register for the Site.</p>
        <p><br></p>
        <p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>
        <p>Unless otherwise indicated, the Site is our proprietary property and all source code, databases,
            functionality, software, website designs, audio, video, text, photographs, and graphics on the Site
            (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”)
            are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and
            various other intellectual property rights and unfair competition laws of the United States, foreign
            jurisdictions, and international conventions. The Content and the Marks are provided on the Site “AS IS” for
            your information and personal use only. Except as expressly provided in these Terms of Use, no part of the
            Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly
            displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any
            commercial purpose whatsoever, without our express prior written permission.</p>
        <p>Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site
            and to download or print a copy of any portion of the Content to which you have properly gained access
            solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to
            the Site, the Content and the Marks.</p>
        <p><br></p>
        <p><br></p>
        <p><strong>USER REPRESENTATIONS</strong></p>
        <p>By using the Site, you represent and warrant that:&nbsp;(1) all registration information you submit will be
            true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly
            update such registration information as necessary;&nbsp;(3) you have the legal capacity and you agree to
            comply with these Terms of Use;&nbsp;(4) you are not a minor in the jurisdiction in which you reside; (5)
            you will not access the Site through automated or non-human means, whether through a bot, script or
            otherwise; (6) you will not use the Site for any illegal or unauthorized purpose; and (7) your use of the
            Site will not violate any applicable law or regulation.</p>
        <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
            suspend or terminate your account and refuse any and all current or future use of the Site (or any portion
            thereof).</p>
        <p><br></p>
        <p><br></p>
        <p><strong>USER REGISTRATION</strong></p>
        <p>You may be required to register with the Site. You agree to keep your password confidential and will be
            responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a
            username you select if we determine, in our sole discretion, that such username is inappropriate, obscene,
            or otherwise objectionable.</p>
        <p><br></p>
        <p><br></p>
        <p><strong>PRODUCTS</strong></p>
        <p>We make every effort to display as accurately as possible the colors, features, specifications, and details
            of the products available on the Site. However, we do not guarantee that the colors, features,
            specifications, and details of the products will be accurate, complete, reliable, current, or free of other
            errors, and your electronic display may not accurately reflect the actual colors and details of the
            products.&nbsp;All products are subject to availability, and we cannot guarantee that items will be in
            stock. We reserve the right to discontinue any products at any time for any reason. Prices for all products
            are subject to change.</p>
        <p><br></p>
        <p><strong><span class="ql-cursor">﻿</span>PURCHASES AND PAYMENT</strong></p>
        <p><strong><span class="ql-cursor">﻿</span></strong></p>
        <p>We accept at least the following forms of payment:&nbsp;</p>
        <ol>
            <li>-&nbsp;Visa</li>
            <li>-&nbsp;Mastercard</li>
            <li>-&nbsp;American Express</li>
            <li>-&nbsp;Discover</li>
            <li>-&nbsp;Bank Transfer</li>
            <li>-&nbsp;Cash (United States Legal Tender)</li>
            <li>-&nbsp;Chase QuickPay</li>
        </ol>
        <p><br></p>
        <p>You agree to provide current, complete, and accurate purchase and account information for all purchases made
            via the Site. You further agree to promptly update account and payment information, including email address,
            payment method, and payment card expiration date, so that we can complete your transactions and contact you
            as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices
            at any time. All payments shall be in U.S. dollars.&nbsp;</p>
        <p>You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping
            fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your
            order.&nbsp;We reserve the right to correct any errors or mistakes in pricing, even if we have already
            requested or received payment.</p>
        <p>We reserve the right to refuse any order placed through the Site. We may, in our sole discretion, limit or
            cancel quantities purchased per person, per household, or per order. These restrictions may include orders
            placed by or under the same customer account, the same payment method, and/or orders that use the same
            billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment,
            appear to be placed by dealers, resellers, or distributors.</p>
        <p><br></p>
        <p><strong>RETURN&nbsp;POLICY</strong></p>
        <p>Please review our Return Policy posted on the Site prior to making any purchases.&nbsp;</p>
        <p><br></p>
        <p><strong>PROHIBITED ACTIVITIES</strong></p>
        <p>You may not access or use the Site for any purpose other than that for which we make the Site available. The
            Site may not be used in connection with any commercial endeavors except those that are specifically endorsed
            or approved by us.</p>
        <p>As a user of the Site, you agree not to:</p>
        <ol>
            <li>Systematically retrieve data or other content from the Site to create or compile, directly or
                indirectly, a collection, compilation, database, or directory without written permission from us.</li>
            <li>Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by
                electronic or other means for the purpose of sending unsolicited email, or creating user accounts by
                automated means or under false pretenses.</li>
            <li>Use a buying agent or purchasing agent to make purchases on the Site.</li>
            <li>&nbsp;Use the Site to advertise or offer to sell goods and services.</li>
            <li>Circumvent, disable, or otherwise interfere with security-related features of the Site, including
                features that prevent or restrict the use or copying of any Content or enforce limitations on the use of
                the Site and/or the Content contained therein.</li>
            <li>Engage in unauthorized framing of or linking to the Site.</li>
            <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                information such as user passwords.</li>
            <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
            <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using
                any data mining, robots, or similar data gathering and extraction tools.</li>
            <li>Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to
                the Site.</li>
            <li>Attempt to impersonate another user or person or use the username of another user.</li>
            <li>Sell or otherwise transfer your profile.</li>
            <li>Use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
            <li>Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for
                any revenue-generating endeavor or commercial enterprise.</li>
            <li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way
                making up a part of the Site.</li>
            <li>Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any
                portion of the Site.</li>
            <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion
                of the Site to you.</li>
            <li>Delete the copyright or other proprietary rights notice from any Content.</li>
            <li>Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other
                code.</li>
            <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material,
                including excessive use of capital letters and spamming (continuous posting of repetitive text), that
                interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions, operation, or maintenance of the Site.</li>
            <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
                information collection or transmission mechanism, including without limitation, clear graphics
                interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes
                referred to as “spyware” or “passive collection mechanisms” or “pcms”).</li>
            <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop,
                or distribute any automated system, including without limitation, any spider, robot, cheat utility,
                scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or
                other software.</li>
            <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
            <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
        </ol>
        <p><br></p>
        <p><strong>MOBILE APPLICATION LICENSE</strong></p>
        <p><br></p>
        <p><strong>﻿Use License</strong></p>
        <p>If you access the Marketplace Offerings via a mobile application, then we grant you a revocable,
            non-exclusive, non-transferable, limited right to install and use the mobile application on wireless
            electronic devices owned or controlled by you, and to access and use the mobile application on such devices
            strictly in accordance with the terms and conditions of this mobile application license contained in these
            Terms of Use. You shall not: (1) decompile, reverse engineer, disassemble, attempt to derive the source code
            of, or decrypt the application; (2) make any modification, adaptation, improvement, enhancement,
            translation, or derivative work from the application; (3) violate any applicable laws, rules, or regulations
            in connection with your access or use of the application; (4) remove, alter, or obscure any proprietary
            notice (including any notice of copyright or trademark) posted by us or the licensors of the application;
            (5) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for
            which it is not designed or intended; (6) make the application available over a network or other environment
            permitting access or use by multiple devices or users at the same time; (7) use the application for creating
            a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute
            for the application; (8) use the application to send automated queries to any website or to send any
            unsolicited commercial e-mail; or (9) use any proprietary information or any of our interfaces or our other
            intellectual property in the design, development, manufacture, licensing, or distribution of any
            applications, accessories, or devices for use with the application.</p>
        <p><strong>Apple and Android Devices</strong></p>
        <p>The following terms apply when you use a mobile application obtained from either the Apple Store or Google
            Play (each an “App Distributor”) to access the Marketplace Offerings: (1) the license granted to you for our
            mobile application is limited to a non-transferable license to use the application on a device that utilizes
            the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth
            in the applicable App Distributor’s terms of service; (2) we are responsible for providing any maintenance
            and support services with respect to the mobile application as specified in the terms and conditions of this
            mobile application license contained in these Terms of Use or as otherwise required under applicable law,
            and you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and
            support services with respect to the mobile application; (3) in the event of any failure of the mobile
            application to conform to any applicable warranty, you may notify the applicable App Distributor, and the
            App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for
            the mobile application, and to the maximum extent permitted by applicable law, the App Distributor will have
            no other warranty obligation whatsoever with respect to the mobile application; (4) you represent and
            warrant that (i) you are not located in a country that is subject to a U.S. government embargo, or that has
            been designated by the U.S. government as a “terrorist supporting” country and (ii) you are not listed on
            any U.S. government list of prohibited or restricted parties; (5) you must comply with applicable
            third-party terms of agreement when using the mobile application, e.g., if you have a VoIP application, then
            you must not be in violation of their wireless data service agreement when using the mobile application; and
            (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and
            conditions in this mobile application license contained in these Terms of Use, and that each App Distributor
            will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in
            this mobile application license contained in these Terms of Use against you as a third-party beneficiary
            thereof.&nbsp;&nbsp;&nbsp;</p>
        <p><br></p>
        <p><strong><span class="ql-cursor">﻿</span>SUBMISSIONS</strong></p>
        <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information
            regarding the Site or the Marketplace Offerings ("Submissions") provided by you to us are non-confidential
            and shall become our sole property. We shall own exclusive rights, including all intellectual property
            rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful
            purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral
            rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or
            that you have the right to submit such Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any proprietary right in your Submissions.</p>
        <p><br></p>
        <p><strong>THIRD-PARTY WEBSITES AND CONTENT</strong></p>
        <p>The Site may contain (or you may be sent via the Site or the Marketplace Offerings) links to other websites
            ("Third-Party&nbsp;Websites") as well as articles, photographs, text, graphics, pictures, designs, music,
            sound, video, information, applications, software, and other content or items belonging to or originating
            from third parties ("Third-Party Content"). Such&nbsp;Third-Party&nbsp;Websites
            and&nbsp;Third-Party&nbsp;Content are not investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any Third Party Websites accessed through the Site or
            any&nbsp;Third-Party&nbsp;Content posted on, available through, or installed from the Site, including the
            content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or
            contained in the&nbsp;Third-Party&nbsp;Websites or the&nbsp;Third-Party&nbsp;Content. Inclusion of, linking
            to, or permitting the use or installation of any&nbsp;Third-Party&nbsp;Websites or
            any&nbsp;Third-PartyContent does not imply approval or endorsement thereof by us. If you decide to leave the
            Site and access the&nbsp;Third-Party&nbsp;Websites or to use or install any&nbsp;Third-Party&nbsp;Content,
            you do so at your own risk, and you should be aware these Terms of Use no longer govern. You should review
            the applicable terms and policies, including privacy and data gathering practices, of any website to which
            you navigate from the Site or relating to any applications you use or install from the Site. Any purchases
            you make through&nbsp;Third-Party&nbsp;Websites will be through other websites and from other companies, and
            we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the
            applicable third party. You agree and acknowledge that we do not endorse the products or services offered
            on&nbsp;Third-Party&nbsp;Websites and you shall hold us harmless from any harm caused by your purchase of
            such products or services. Additionally, you shall hold us harmless from any losses sustained by you or harm
            caused to you relating to or resulting in any way from any&nbsp;Third-Party&nbsp;Content or any contact
            with&nbsp;Third-Party&nbsp;Websites.</p>
        <p><br></p>
        <p><strong><span class="ql-cursor">﻿</span>SITE MANAGEMENT</strong></p>
        <p>We reserve the right, but not the obligation, to:&nbsp;(1) monitor the Site for violations of these Terms of
            Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these
            Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our
            sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable
            (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in&nbsp;our
            sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable
            all files and content that are excessive in size or are in any way burdensome to our systems; and (5)
            otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the
            proper functioning of the Site and the Marketplace Offerings.</p>
        <p><br></p>
        <p><br></p>
        <p><strong>PRIVACY POLICY</strong></p>
        <p>We care about data privacy and security. Please review our Privacy Policy here:<strong>&nbsp;</strong><a
                data-cke-saved-href="https://medusadistribution.com/pages/privacy-policy"
                href="https://medusadistribution.com/pages/privacy-policy" target="_blank"><strong>Medusa Privacy
                    Policy</strong></a>. By using the Site or the Marketplace Offerings, you agree to be bound by our
            Privacy Policy, which is incorporated into these Terms of Use. Please be advised the Site and the
            Marketplace Offerings are hosted in the United States. If you access the Site or the Marketplace Offerings
            from the European Union, Asia, or any other region of the world with laws or other requirements governing
            personal data collection, use, or disclosure that differ from applicable laws in the United States, then
            through your continued use of the Site, you are transferring your data to the United States, and you
            expressly consent to have your data transferred to and processed in the United States.&nbsp;Further, we do
            not knowingly accept, request, or solicit information from children or knowingly market to children.
            Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual
            knowledge that anyone under the age of 13 has provided personal information to us without the requisite and
            verifiable parental consent, we will delete that information from the Site as quickly as is reasonably
            practical.</p>
        <p><br></p>
        <p><strong>TERM AND TERMINATION</strong></p>
        <p>These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER
            PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
            LIABILITY, DENY ACCESS TO AND USE OF THE SITE AND THE MARKETPLACE OFFERINGS (INCLUDING BLOCKING CERTAIN IP
            ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
            REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE AND THE MARKETPLACE OFFERINGS OR
            DELETE&nbsp;YOUR ACCOUNT AND&nbsp;ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.&nbsp;</p>
        <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a
            new account under your name, a fake or borrowed name, or the name of any third party, even if you may be
            acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the
            right to take appropriate legal action, including without limitation pursuing civil, criminal, and
            injunctive redress.</p>
        <p><br></p>
        <p><strong>MODIFICATIONS AND INTERRUPTIONS</strong></p>
        <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at
            our sole discretion without notice. However, we have no obligation to update any information on our Site. We
            also reserve the right to modify or discontinue all or part of the Marketplace Offerings without notice at
            any time. We will not be liable to you or any third party for any modification, price change, suspension, or
            discontinuance of the Site or the Marketplace Offerings.&nbsp;</p>
        <p>We cannot guarantee the Site and the Marketplace Offerings will be available at all times. We may experience
            hardware, software, or other problems or need to perform maintenance related to the Site, resulting in
            interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or
            otherwise modify the Site or the Marketplace Offerings at any time or for any reason without notice to you.
            You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site or the Marketplace Offerings during any downtime or discontinuance of
            the Site or the Marketplace Offerings. Nothing in these Terms of Use will be construed to obligate us to
            maintain and support the Site or the Marketplace Offerings or to supply any corrections, updates, or
            releases in connection therewith.</p>
        <p><strong>GOVERNING LAW</strong></p>
        <p>These Terms of Use and your use of the Site and the Marketplace Offerings are governed by and construed in
            accordance with the laws of the&nbsp;State of&nbsp;New Jersey&nbsp;applicable to agreements made and to be
            entirely performed within&nbsp;the&nbsp;State of&nbsp;New Jersey,&nbsp;without regard to its conflict of law
            principles.&nbsp;</p>
        <p><strong>DISPUTE RESOLUTION</strong></p>
        <p><strong>Binding Arbitration</strong></p>
        <p>If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those
            Disputes expressly excluded below) will be finally and exclusively resolved by binding arbitration. YOU
            UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American
            Arbitration Association ("AAA") and, where appropriate, the AAA’s Supplementary Procedures for Consumer
            Related Disputes ("AAA Consumer Rules"), both of which are available at the AAA website&nbsp;<a
                data-cke-saved-href="http://www.adr.org/" href="http://www.adr.org/" target="_blank">www.adr.org</a>.
            Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA Consumer Rules
            and, where appropriate, limited by the AAA Consumer Rules.&nbsp;The arbitration may be conducted in person,
            through the submission of documents, by phone, or online. The arbitrator will make a decision in writing,
            but need not provide a statement of reasons unless requested by either Party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator fails to do so. Except where otherwise
            required by the applicable AAA rules or applicable law, the arbitration will take place in&nbsp;Essex
            County,&nbsp;NJ. Except as otherwise provided herein, the Parties may litigate in court to compel
            arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the
            award entered by the arbitrator.</p>
        <p><br></p>
        <p>If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be commenced or
            prosecuted in the state and federal courts located in&nbsp;Essex County,&nbsp;NJ, and the Parties hereby
            consent to, and waive all defenses of lack of personal jurisdiction, and forum non conveniens with respect
            to venue and jurisdiction in such state and federal courts.&nbsp;Application of the United Nations
            Convention on Contracts for the International Sale of Goods and the the Uniform Computer Information
            Transaction Act (UCITA) are excluded from these Terms of Use.&nbsp;</p>
        <p><br></p>
        <p>In no event shall any Dispute brought by either Party related in any way to the Site be commenced more
            than&nbsp;one (1)&nbsp;years after the cause of action arose.&nbsp;If this provision is found to be illegal
            or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this
            provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent
            jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the
            personal jurisdiction of that court.</p>
        <p><br></p>
        <p><strong>Restrictions</strong></p>
        <p>The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To
            the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is
            no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute to be brought in a purported
            representative capacity on behalf of the general public or any other persons.</p>
        <p><br></p>
        <p><strong>Exceptions to Arbitration</strong></p>
        <p>The Parties agree that the following Disputes are not subject to the above provisions concerning binding
            arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the
            intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft,
            piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision
            is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling
            within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided
            by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree
            to submit to the personal jurisdiction of that court.</p>
        <p><br></p>
        <p><strong>CORRECTIONS</strong></p>
        <p>There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may
            relate to the Marketplace Offerings, including descriptions, pricing, availability, and various other
            information.&nbsp;We reserve the right to correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior notice.</p>
        <p><br></p>
        <p><strong>DISCLAIMER</strong></p>
        <p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE SERVICES WILL BE
            AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
            IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES
            LINKED TO THIS SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
            INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
            RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
            SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
            LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS
            IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
            CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
            GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
            THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
            OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
            BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
            SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
            WHERE APPROPRIATE.</p>
        <p><br></p>
        <p><strong>LIMITATIONS OF LIABILITY</strong></p>
        <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT,
            LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp;NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
            LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
            LIMITED TO&nbsp;THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON
            IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
            ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
        <p>&nbsp;&nbsp;</p>
        <p><strong>INDEMNIFICATION</strong></p>
        <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
            respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim,
            or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out
            of:&nbsp;(1) use of the Site; (2) breach of these Terms of Use; (3) any breach of your representations and
            warranties set forth in these Terms of Use; (4) your violation of the rights of a third party, including but
            not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Site
            with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense,
            to assume the exclusive defense and control of any matter for which you are required to indemnify us, and
            you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to
            notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming
            aware of it.</p>
        <p>&nbsp;&nbsp;</p>
        <p><strong>USER DATA</strong></p>
        <p><br></p>
        <p>We will maintain certain data that you transmit to the Site for the purpose of managing the performance of
            the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of
            data, you are solely responsible for all data that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of
            any such data, and you hereby waive any right of action against us arising from any such loss or corruption
            of such data.</p>
        <p><br></p>
        <p><strong>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong></p>
        <p>Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You
            consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and
            other communications we provide to you electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
            CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
            TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under
            any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original
            signature or delivery or retention of non-electronic records, or to payments or the granting of credits by
            any means other than electronic means.</p>
        <p><br></p>
        <p><strong>CALIFORNIA USERS AND RESIDENTS</strong></p>
        <p>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the
            Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North
            Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at&nbsp;<a
                data-cke-saved-href="tel:(800)%20952-5210" href="tel:(800)%20952-5210" target="_blank">(800)
                952-5210</a>&nbsp;or&nbsp;<a data-cke-saved-href="tel:(916)%20445-1254" href="tel:(916)%20445-1254"
                target="_blank">(916) 445-1254</a>.</p>
        <p><br></p>
        <p><br></p>
        <p><strong>﻿MISCELLANEOUS</strong></p>
        <p>These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site
            constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any
            right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and
            obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or
            failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of
            these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the
            provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership, employment or agency relationship created
            between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use
            will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you
            may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to
            execute these Terms of Use.</p>
        <p><br></p>
        <p><strong>CONTACT US&nbsp;</strong></p>
        <p><strong><span class="ql-cursor">﻿</span></strong></p>
        <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the
            Site, please contact us at:</p>
        <p><br></p>
        <p><strong>Medusa Distribution LLC</strong></p>
        <p>9 Woodland Rd. Suite D</p>
        <p>Roseland, NJ 07068</p>
        <p>(973) 808-1101</p>
    </div>
</template>


<script>
export default {
    name: 'Terms',
};
</script>
